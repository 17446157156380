// IMPORTS
@import (reference) "../settings.less";

.main-page-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
}

// MAIN BANNER
.main-banner {
    width: 100%;
    margin: 0;

    .swiper-slide {
        display: block;
    }

    .banner-img-wrapper {
        aspect-ratio: 1117 / 383;
        position: relative;
    }

    img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    b {
        position: absolute;
        bottom: 36px;
        left: 18px;
        background-color: @main-color;
        color: white;
        font-weight: 300;
        font-size: 22px;
        font-family: @sec-font-family;
        text-transform: uppercase;
        padding: 15px 30px;
    }
}

// GREETING
.main-greeting {
    
    h1.headline {
        margin-bottom: 0;

        & + .user-custom-content {
            margin-top: 24px;
        }
    }

    .user-custom-content {
        line-height: 1.8;
    }
}

// STATIC BANNER
.static-banner {
    .grid();
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 40px;

    a {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        align-items: center;
        text-align: center;
        padding: 20px;
        gap: 10px;
        transition: box-shadow .2s ease-in-out;

        @media (min-width: 1280px) {
            &:hover {
                box-shadow: 0 0 13px rgba(0,0,0,.10) inset;

                img {
                    transform: scale(1.04);
                }
            }
        }
    }

    figure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
        aspect-ratio: 150 / 100;
        max-width: 100%;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        transform: scale(1);
        transition: transform .2s ease-in-out;
    }

    span {
        color: @header-color;
    }
}
